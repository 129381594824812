import "./home.css"
import React, { useEffect, useRef } from "react";


function Home() {
    
       const backgroundRef = useRef(null);
    useEffect(() => {
          // Check if the user is on a mobile device (iPhone, iPad, Android)
          if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            // Add scroll event listener to simulate the fixed background effect
            const handleScroll = () => {
              const scrollPosition = window.pageYOffset;
              backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
            };
      
            window.addEventListener('scroll', handleScroll);
      
            // Cleanup the event listener when the component is unmounted
            return () => {
              window.removeEventListener('scroll', handleScroll);
            };
          }
        }, []); // Empty dependency array means this effect runs once, when the component mounts
           
    
    
     return (
          <div className="home">
               <div ref={backgroundRef} className="intro2">
               <div>
                    <img src="/img/robotmeetsnature.jpg" className="cover" alt="background">
                    </img>
                    <div className="teaser">
                         It´s facinating to see how our Robots getting in touch with nature
                         <br></br>
                         they´re keeping your Plants alife and looking for their needs 
                         <br></br> 
                         The results stand for theirselfs

                        
                    </div>
               </div>
            </div>   
          </div>
     )
}

export default Home

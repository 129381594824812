import "./start.css"

import React, { useEffect, useRef } from "react";

function Start() {


          const backgroundRef = useRef(null);
      useEffect(() => {
            // Check if the user is on a mobile device (iPhone, iPad, Android)
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
              // Add scroll event listener to simulate the fixed background effect
              const handleScroll = () => {
                const scrollPosition = window.pageYOffset;
                backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
              };
        
              window.addEventListener('scroll', handleScroll);
        
              // Cleanup the event listener when the component is unmounted
              return () => {
                window.removeEventListener('scroll', handleScroll);
              };
            }
          }, []); // Empty dependency array means this effect runs once, when the component mounts
           
         
     



     return (
          <div className="home">
               <div ref={backgroundRef} className="intro">
               
               <div className="teaser">
                         Getting W-lan access
                         
               </div>
               
               
               <br></br>
              <p className="paragraph elem1">
              <br></br>
              <br></br>
              <img src="/img/raspi_plugs.png" className="cover7" alt="tobeadded"></img>
              <br></br>
              <br></br>
                         To connect your Feeder to your Wifi you need a thew thigs:
                         <br></br>
                              <ol className="liste">
                                   <li>plug in a micro HDMI-Cable and connect a monitor</li>
                                   <li>plug in a Keyboard</li>
                                   <li>plug in a Mouse</li>
                                   <li>then connect the Power-Cable</li>
                              </ol>          
                         The Feeder boots to Desktop. At the right top of your screen appears a connection sign. Click on it and choose the right SSID.
                         Then type in the password and hit Connect button. 
               

               <br></br>
               
               <br></br>
               <img src="/img/raspiwlan.png" className="cover6" alt="nutribot"></img>
               <br></br>
               <br></br>
               Congratulations!!! You just connected your Wifi.
                         <br></br>
                         <br></br>
                         Now your Feeder is available on http://feeder.local:8080
                         <br></br>

               </p>
               <p className="paragraph elem2">
               <div className="teaser">
               Get Wan Access
                         
               </div> 
                         <br></br>
                                       
                         To feed your plants from all around the world you need only a static IP address and a Portforwarding configuration for your Router.
               </p>
               </div>
               
          </div>
     )
}

export default Start

import "./nutribot.css"
import React, { useEffect, useRef } from "react";
function Nutribot() {
      const backgroundRef = useRef(null);
  useEffect(() => {
        // Check if the user is on a mobile device (iPhone, iPad, Android)
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          // Add scroll event listener to simulate the fixed background effect
          const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
          };
    
          window.addEventListener('scroll', handleScroll);
    
          // Cleanup the event listener when the component is unmounted
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }
      }, []); // Empty dependency array means this effect runs once, when the component mounts
       
         
     return (
          <div className="home">
               <div ref={backgroundRef} className="intro2">
               
                    <div className="teaser">
                         Three simple steps to success
                         <br></br>
                         
                         <img src="/img/nutribot2.png" className="cover2" alt="nutribot"></img>
                         <br></br>
                         Get fresh Water, Get some Nutrients, Feed'em 
                         <br></br> 
                         <div className="teaser2" >
                              The full Nutribot system, feeds whatever you want  
                                   
                         </div>

                         




                         <div className="wrapper">
                         
                         <h1 className="teaser">Smart Irrigation System – Effortless Control, Anytime, Anywhere</h1>
    
    <p className="teaser2">Take your plant care to the next level with our cutting-edge IoT irrigation control system. Designed for growers who want precision and convenience, this smart system lets you monitor and manage your watering and nutrient delivery remotely, keeping your plants healthy and thriving no matter where you are.</p>

    <p className="teaser2">At its core, the system features 4 independent zones, each equipped with advanced <strong>VWC</strong> (Volumetric Water Content) and <strong>TDS</strong> (Total Dissolved Solids) sensors. These sensors continuously track soil moisture and nutrient levels, ensuring your plants get exactly what they need, when they need it.</p>

    <p className="teaser2">With a built-in web server and data logger, you can access your system from anywhere using DyDNS or a static IP, with the option to connect through a custom domain. Whether you’re at home or on the go, you’ll always be in the know.</p>

    <h2 className="teaser">Key Features:</h2>
    <ul>
        <li className="teaser2"><strong className="teaser">Vacation Mode:</strong> Automatically adjusts watering schedules based on real-time conditions, or manually control it remotely.</li>
        <li className="teaser2"><strong className="teaser">Customizable Watering:</strong> When a dry pot is detected, the system automatically delivers the right amount of water, with adjustable thresholds to accommodate different soil types.</li>
        <li className="teaser2"><strong className="teaser">Pre-Pump Connections:</strong> Easily expand your system with two pre-pump connections: one for water transfer and the other for nutrient delivery. Transform your setup into a fully automated fertilizing system.</li>
        <li className="teaser2"><strong className="teaser">Post-Pump Connection:</strong> Add a powerful post-pump like the Gardena City system for larger-scale irrigation needs.</li>
    </ul>

    <p className="teaser2">Effortless control, seamless integration, and powerful automation—our smart irrigation system takes the hassle out of plant care so you can focus on what really matters: growing.</p>

                        
       
                          
                          
                        
                         </div>
                         
                    </div>
               </div>
               
          </div>
     )
}

export default Nutribot

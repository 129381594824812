import detectBrowserLanguage from "detect-browser-language"
import useStore from "../ZustandManager/zustand.js"
import "./about.css"
import React, { useEffect, useRef } from "react";

function About() {



     let language = useStore((state) => state.language)
     const setLanguage = useStore((state) => state.setLanguage)
     let lang = detectBrowserLanguage()

     if (lang === "de-AT") {
          setLanguage("de")
     } else if (lang === "en-US") {
          setLanguage("en")
     } else if (lang === "de") {
          setLanguage("de")
     } else if (lang === "it-IT") {
          setLanguage("it")
     } else if (lang === "it") {
          setLanguage("it")
     } else {
          setLanguage("en")
     }

     function me() {
          if (language !== "none") {
               if (language === "de") {
                    return (
                         <div>
                              <div>
                                   
                              </div>
                              <br></br>
                              <div>
                                  
                              </div>
                              <br></br>
                              <div>
                                  
                              </div>
                         </div>
                    )
               } else if (language === "en") {
                    return (
                         <div>
                              <div>
                              My name is Philipp and my company Feeder has it`s roots in my passion for cultivating crops of all kinds. Since my early try`s,
                                   I have tested a bunch of kinds of fertilizers and additives. I've also pretty much tried out what can be made with
                                   automatic irregation systems and combined my knowledge to my products. The Nutribot combines a wide flexibility with the precision of an automation system.
                                   This Product is for everyone cultivating on soil. No mather if you are using fertilizer or working with living soil. The full system allows an automatic mode
                                   with barely no intervention needed. The Sensor in the soil monitores the moisture level and automatically feed`s if it`s needed.
                                   Therefore there`s no too dry soil anymore what is specially beneficial for living soil, but also for mineralic feeding very important.
                                   This is what makes the Nutribot that unique. It`s the result of years of mixing nurients by hand and getting tired of spending so much time on it.
                              </div>
                         </div>
                    )
               } else if (language === "it") {
                    return (
                         <div>
                              <div>
                              My name is Philipp and my company Feeder has it`s roots in my passion for cultivating crops of all kinds. Since my early try`s,
                                   I have tested a bunch of kinds of fertilizers and additives. I've also pretty much tried out what can be made with
                                   automatic irregation systems and combined my knowledge to my products. The Nutribot combines a wide flexibility with the precision of an automation system.
                                   This Product is for everyone cultivating on soil. No mather if you are using fertilizer or working with living soil. The full system allows an automatic mode
                                   with barely no intervention needed. The Sensor in the soil monitores the moisture level and automatically feed`s if it`s needed.
                                   Therefore there`s no too dry soil anymore what is specially beneficial for living soil, but also for mineralic feeding very important.
                                   This is what makes the Nutribot that unique. It`s the result of years of mixing nurients by hand and getting tired of spending so much time on it.

                              </div>
                         </div>
                    )
               } else {
                    return (
                         <div>
                              <div>
                                   My name is Philipp and my company Feeder got it`s roots in my passion for cultivating crops of all kinds. Since my early try`s,
                                   I have tested a bunch of kinds of fertilizers and additives. I've also pretty much tried out what can be made with
                                   automatic irregation systems and combined my knowledge to my products. The Nutribot combines a wide flexibility with the precision of an automation system.
                                   This Product is for everyone cultivating on soil. No mather if you are using fertilizer or working with living soil. The full system allows an automatic mode
                                   with barely no intervention needed. The Sensor in the soil monitores the moisture level and automatically feed`s if it`s needed.
                                   Therefore there`s no too dry soil anymore what is specially beneficial for living soil, but also for mineralic feeding very important.
                                   This is what makes the Nutribot that unique. It`s the result of years of mixing nurients by hand and getting tired of spending so much time on it.

                                 



                              </div>
                              <br></br>
                              <div>
                                  
                              </div>
                              <br></br>
                              <div>
                                   
                              </div>
                         </div>
                    )
               }
          }
     }


     const backgroundRef = useRef(null);
 useEffect(() => {
       // Check if the user is on a mobile device (iPhone, iPad, Android)
       if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
         // Add scroll event listener to simulate the fixed background effect
         const handleScroll = () => {
           const scrollPosition = window.pageYOffset;
           backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
         };
   
         window.addEventListener('scroll', handleScroll);
   
         // Cleanup the event listener when the component is unmounted
         return () => {
           window.removeEventListener('scroll', handleScroll);
         };
       }
     }, []); // Empty dependency array means this effect runs once, when the component mounts
      
    




     return (
          <div ref={backgroundRef} className="welcomee">
               <h1 className="welcome h1"> the founder of "the Feeder" </h1>
               <div className="megrid">
                    <div className="me">
                         <div className="bild">
                              <img className="imgdreli" src="/img/phil.jpg" alt="phil" />
                         </div>
                         <div>
                              <p className="beautyme">{me()} </p>
                         </div>
                    </div>
               </div>
          </div>
     )
}
export default About

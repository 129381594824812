import "./nutribotliving.css"
import React, { useEffect, useRef } from "react";
const NutribotLiving = () => { 
      const backgroundRef = useRef(null);
  useEffect(() => {
        // Check if the user is on a mobile device (iPhone, iPad, Android)
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
          // Add scroll event listener to simulate the fixed background effect
          const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            backgroundRef.current.style.backgroundPosition = `center ${scrollPosition * 0.5}px`;
          };
    
          window.addEventListener('scroll', handleScroll);
    
          // Cleanup the event listener when the component is unmounted
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }
      }, []); // Empty dependency array means this effect runs once, when the component mounts
       
   
     return (
          <div className="home">
               <div ref={backgroundRef} className="intro2">
               
                    <div className="teaser">
                         One simple step to success
                         <br></br>
                         
                         <img src="/img/Feeder-ultimate-Grafik.png" className="cover5" alt="nutribot"></img>
                         <br></br>
                         Get fresh Water 
                         <br></br> 
                         <div className="teaser2" >
                              The most important thing,               
                              growing on living soil,                
                              is to keep the moisture of the soil    
                                   
                         </div>
                         <div className="wrapper">
                         
                         <h1 className="teaser">Introducing the Octopus: The Smart Irrigation Controller for Indoor Gardeners!</h1>
                         <p  className="teaser2">Say goodbye to manual watering and hello to effortless plant care with the Octopus, the ultimate irrigation solution for hobby gardeners and indoor growers. This easy-to-use, IoT-powered irrigation controller offers complete automation, ensuring your plants get the right amount of water at the right time – all while you're away or busy with other tasks.</p>
                         
                         <h2>Key Features:</h2>
                         <ul>
                              <li className="teaser2"><strong>8 Inputs, 8 Outputs:</strong> The Octopus comes with 8 input ports and 8 output ports, allowing you to connect up to 8 VWC (Volumetric Water Content) sensors and 8 pumps for precise irrigation.</li>
                              <li className="teaser2"><strong>Customizable Setup:</strong> Order the base system and select the number of sensors and pumps you need for your unique growing environment. The flexibility ensures your system is tailored to your specific requirements.</li>
                              <li className="teaser2"><strong>Smart Irrigation:</strong> Each sensor controls a pump, ensuring your plants receive optimal watering based on real-time soil moisture data.</li>
                              <li className="teaser2"><strong>Remote Control:</strong> With the ability to host a website via static IP or DynDNS, you can monitor and manage your system from anywhere in the world. Perfect for when you're away on vacation – just activate the “Vacation Mode” for peace of mind.</li>
                              <li className="teaser2"><strong>Seamless Integration:</strong> The Octopus system is compatible with a wide range of sensors and pumps, making it easy to integrate into your existing setup.</li>
                         </ul>
                        
                        
                          
                          
                          
                        
                         </div>
                         

                    </div>
               </div>
               
          </div>
     )
}

export default NutribotLiving
